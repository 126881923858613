import React, {useState, useEffect, useCallback} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';

export default function LanguageSelector (props) {
    //const [language, setLanguage] = useState('en-US');
    //const [languageList, setLanguageList] = useState([]);
    const [mounted, setMounted] = useState(false);
    const history = useHistory();


    const languages = [
        {
            name: '🇩🇪 Deutsch',
            link: 'de'
        },
        {
            name: '🇺🇸 English',
            link: 'en'
        },
        {
            name: '🇪🇸 Español',
            link: 'es'
        },
        {
            name: '🇫🇷 Français',
            link: 'fr'
        },
        {
            name: '🇭🇺 Magyar',
            link: 'hu'
        },
        {
            name: '🇳🇱 Nederlands',
            link: 'nl'
        },
        {
            name: '🇷🇴 Română',
            link: 'ro'
        },
        {
            name: '🇸🇪 Svenska',
            link: 'sv'
        }
    ];
    useEffect(() => {
 
        //console.log('language: ' + props.language);

        //setLanguageList(languages);
        setMounted(true);

    }, []); 

    const doLanguageChange = useCallback(e => {        
        history.push('/' + e.target.value);
      }, []);   
  
    
    if (!mounted) 
        return '';
    else 
    
    return (
        <select value={props.language} onChange={doLanguageChange} className='cursor-pointer outline-none'>
            {languages.map(function(item, index){
                return (
                    <option key={index} value={item.link}>{item.name}</option>
                    )
                }
            )}
        </select>
    )
}