

export default function MaintenanceBar(props) {
  return (
    <div className="text-center gap-x-6 bg-red-700 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white text-center">
      ⚠️ {props.reason} ⚠️
      </p>
    </div>
  )
}
