import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useCandidate } from "./CandidateContext";

function ProtectedRoute ({ component: Component, ...rest }) {
  const {candidate} = useCandidate();

  return (
    <Route
      {...rest}
      render={props =>
        candidate ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default ProtectedRoute;
