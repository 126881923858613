import React, {useCallback} from 'react';
import { useTranslation } from 'react-i18next'
import Header from './Header';

export default function Feedback(props) {
    const { t } = useTranslation();

    const handleFeedbackLevelChange = (val)  => {        
        props.setFeedbackLevel(val)                          
      };

    const handleFeedbackTextChange = (val) => {        
        props.setFeedbackText(val)                          
    };


  
    return (
        <>
        <Header />
        <div className='container mx-auto bg-blue-50 rounded-md p-4 lg:text-lg'>
                <p className='font-bold text-center'>{t('feedback.IntroTitle')} </p>
                <br />
                <p>{t('feedback.IntroLine')} </p>

                <br /><br />
                <p>{t('feedback.RateLabel')} </p>
                <select onChange={e => {handleFeedbackLevelChange(e.target.value)}} className='border-black border-2 p-1 rounded'>
                    <option value=''></option>
                    <option value='E'>{t('feedback.RateEasy')}</option>
                    <option value='M'>{t('feedback.RateMedium')}</option>
                    <option value='H'>{t('feedback.RateDifficult')}</option>
                </select>
                <br /><br />
                <p>{t('feedback.OtherFeedback')}</p>
                <textarea onChange={e => {handleFeedbackTextChange(e.target.value)}} className='border-black border-2 p-1 rounded w-full sm:w-1/2'></textarea>

        </div>
        <div className='flex justify-center my-4'>
            <button onClick = {props.doSubmit} className='bg-green-600 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>{t('feedback.BtnSave')}</button>
        </div>
        </>

    )
}