import React, {useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import TestCenterService from '../../services/TestCenterService';
import Footer from './Footer';
import NotFound from '../NotFound/Index';
import validator from 'validator';

export default function Index (props) {

    const [wait, setWait] = useState(false);
    const [site, setSite]= useState('test');
    const [company, setCompany] = useState('Skillmeter');
    const [mounted, setMounted] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [testId, setTestId] = useState(0);
    const [testName, setTestName]= useState('');
    const [userId, setUserId] = useState(0);
    const [candidateId, setCandidateId] = useState(0);
    const [errors, setErrors] = useState({
        email : false,
        emailErr: null,
        firstName: false, 
        firstNameErr: null,
        lastName: false, 
        lastNameErr: null,
        serviceErr: null

      });

      
    const getSubdomain =  () => {
        const parts = window.location.hostname.split('.');
    
        let last_index = -2;
        const last = parts[parts.length - 1];
        const is_localhost = last === 'localhost';
        if (is_localhost) {
          last_index = -1;
        }
    
        const subdomain = parts.slice(0, last_index).join('.');
    
        if (!subdomain) {
          return '';
        }
        else {
            return subdomain;
        }
      }


    useEffect( async () => {

        // get company details
        const subdomain = getSubdomain();
        setSite(subdomain);
        if (subdomain !== '' && subdomain !== 'localhost' && subdomain !== 'test' && subdomain !== 'testcenter') {
            const comp = await TestCenterService.getCompanyDetails(subdomain);
            if (comp && comp !=='') {
                setCompany(comp);
            }
        }

        // get test details based on url
        //console.log('test url = ' + props.id);
        const test = await TestCenterService.getTestByUrl(props.id);
        //console.log(test ? test : 'test is null');

        if (test ) {
            setTestId(test.TestId);
            setTestName(test.Name);
            setUserId(test.UserId);
        }
        setMounted(true);

    }, []);

    const doValidate =  () => {
        let hasErrors = false;
        let errorsCheck  = [{
          email : false,
          firstName: false,
          lastName: false,
        }];
    
        if (validator.isEmpty(firstName) || !validator.isAlpha(firstName)) {
          hasErrors = true;
          errorsCheck.firstName = true;
          errorsCheck.firstNameErr = 'Invalid first name';
        } 

        if (validator.isEmpty(lastName) || !validator.isAlpha(lastName)) {
            hasErrors = true;
            errorsCheck.lastName = true;
            errorsCheck.lastNameErr = 'Invalid last name';
          } 
          
        if (validator.isEmpty(email) || !validator.isEmail(email) ) {
          hasErrors = true;
          errorsCheck.email = true;
          errorsCheck.emailErr = 'Invalid email';
        } 
    
        setErrors(errorsCheck);
        return hasErrors;
      }
    
    const doSubmit = async() => {
        setWait(true);
        
        if (!doValidate())
        {
            const ret =  await TestCenterService.registerForTest(firstName, lastName, email, userId, testId, testName, site, (company == 'Skillmeter' ? 'Skillmeter' : company.CompanyName));
            //console.log(ret);

            if (ret) {

                if (ret.candidateId > 0)
                    setCandidateId(ret.candidateId);
                
                if (ret.errMessage)
                    setErrors({serviceErr: ret.errMessage});
            }

        }
        // done
        setWait(false);
    }


    if (!mounted) 
    return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else 
    if (testName == '')
    return (
        <NotFound message='Oops... The test you were looking for does not exist.'/>
    )
    else
    return (
        <>
        <div className=''>

            <div className={'mb-10 mt-10 text-center '}>
                {company && company.Logo ?
                <img src={process.env.REACT_APP_CDN_URL + '/company_img/' + company.Logo} className='mx-auto' style={{'max-height': '100px'}}/>
                : 
                <img src='/skillmeter_logo.png' className='mx-auto w-52'/>

            }
            </div>    
            <p className='text-center font-bold text-lg'>Welcome to {company == 'Skillmeter' ? 'Skillmeter' : company.CompanyName} test center</p>

            {candidateId === 0 ?
            <>
            <p className='text-center text-lg mt-4'>Please provide your details to register for this test: <b>{testName}</b></p>

            <br /><br />
            <div className="grid sm:grid-cols-2 gap-2 md:gap-4 mt-4">

                <div className='text-center sm:text-right'>
                    <label htmlFor='firstname' className='text-lg'>Your first name</label>
                </div>
                <div className='text-center sm:text-left'>
                    <input type='text' name='firstname' onChange={e => { setFirstName(e.target.value.trim());}}className='px-2 border-black border-2 rounded text-lg focus:outline-none'/>           
                    {errors.firstNameErr ? <><br /><span className='text-red-500 '>{errors.firstNameErr}</span></> :<></>}

                </div>
                <div className='text-center sm:text-right'>
                    <label htmlFor='lastname' className='text-lg'>Your last name</label>
                </div>
                <div className='text-center sm:text-left'>
                    <input type='text' name='lastname' onChange={e => { setLastName(e.target.value.trim());}}className='px-2 border-black border-2 rounded text-lg focus:outline-none'/>
                    {errors.lastNameErr ? <><br /><span className='text-red-500 '>{errors.lastNameErr}</span></> :<></>}

                </div>
                <div className='text-center sm:text-right'>
                    <label htmlFor='email' className='text-lg'>Your email</label>
                </div>
                <div className='text-center sm:text-left'>
                    <input type='text' name='email' onChange={e => { setEmail(e.target.value.trim());}}className='px-2 border-black border-2 rounded text-lg focus:outline-none'/>
                    {errors.emailErr ? <><br /><span className='text-red-500 '>{errors.emailErr}</span></> :<></>}

                </div>

            </div>
            {errors.serviceErr ? <div className='text-center text-red-500 my-4'>{errors.serviceErr}</div> :<></>}

            <div className='flex justify-center my-4'>

                <button onClick={() => {doSubmit()}} className="flex justify-center text-center whitespace-pre bg-green-600 hover:bg-green-700 text-white font-bold text-lg py-1 px-2 rounded focus:outline-none">Submit
                    {wait ?  
                    <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>: ''}
                </button>
            </div>
            </>
            :
            <>
                <p className='text-center text-lg mt-4'>You are now registered for <b>{testName}</b>.  Please check your email for instructions on how to access the test.</p>
                <p className='text-center'><Link to='/' className='underline text-blue-600 hover:text-blue-800'>Go to the test center</Link></p>

            </>}
        </div>

        <Footer />
    </>
    );
}