import React, {useState, useEffect} from 'react';
import {useCandidate} from '../../CandidateContext';
import Comments from './Comments';
import Loader from "react-loader-spinner";
import { MathJax } from 'better-react-mathjax';

export default function QuestionSingleAnswer(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [mounted, setMounted] = useState(false);

    const options= [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    useEffect( () => {        
        //console.log('loading single question')
        setResponse(props.question)
        setMounted(true);
    }, [props.question]);


    const saveQuestionAnswers = async(val)  => {      
        //console.log(val);  
        // reset old values
        response['R_1'] = null;
        response['R_2'] = null;
        response['R_3'] = null;
        response['R_4'] = null;
        response['R_5'] = null;
        response['R_6'] = null;
        response['R_7'] = null;
        response['R_8'] = null;
        response['R_9'] = null;
        response['R_10'] = null;
        response['R_11'] = null;
        response['R_12'] = null;
        
        // set new value        
        var r = val;
        r = r.replace('A', 'R');
        response[r] = true;

        setResponse(response);
        //console.log('response');
        //console.log(response)
        setCandidateData();
    };

    const saveQuestionComments = async(val) => {   
        //var resp = {...response};     
        response['R_Text'] = val;
        setResponse(response);

        setCandidateData();
    };

    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else


    return (

        <div>

            {options.map((i) => {
                // if the value starts with $$ means it's a latex math expression so apply MathJax, else display original text
                // online latex editor: https://latexeditor.lagrida.com/
                return props.question['A_' + i] ?                 
                    <div key={i}><input name='anwers' type='radio' id={'id' +i} className='mr-2' value={'A_' + i} checked={props.question['R_' + i] == true} onChange={e => {saveQuestionAnswers(e.target.value);}} /><label htmlFor={'id'+i}>{props.question['A_' + i].startsWith('$$') ? <MathJax>{props.question['A_' + i]}</MathJax> : props.question['A_' + i]}</label></div>
                    : ''
            })

            }
            {props.question.Comments == true ? <Comments saveQuestionComments={saveQuestionComments} value={response['R_Text'] ? response['R_Text'] : ''}></Comments> : <></>}
        </div>
    )
}