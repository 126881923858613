import {useState, useEffect} from 'react';
import axios from 'axios';


/// fetch maintenance status, returns a json with maintenance: true/false and reason
export function useMaintenanceStatus() {
    const [isMaintenance, setIsMaintenance] = useState(false);
    
    useEffect(() => {
        const checkMaintance = async () => {
            const status = await axios.get("https://status.skillmeter.com/");

            if (status) {
                if (process.env.REACT_APP_ENV == "development") {
                    setIsMaintenance(status.data.development)
                }
                else {
                    setIsMaintenance(status.data.production)
                }
            }
          }
          checkMaintance();
    }, [])

    return isMaintenance;
}