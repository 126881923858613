import {Link} from 'react-router-dom';

export default function Index (props) {
    return (
    <>
        <div className={'mb-10 mt-10 text-center '}>
            <img src='/skillmeter_logo.png' className='mx-auto w-52'/>
        </div>    
    
        <div className='container mx-auto text-center p-4 lg:text-lg'>
            <p>{props.message ? props.message : 'Oops... The page you were looking for does not exist.'}</p>
            <p><Link to='/' className='underline text-blue-600 hover:text-blue-800'>Go back</Link></p>
        </div>;
    </>)
}