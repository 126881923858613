import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import Loader from "react-loader-spinner";
import {useCandidate} from '../../CandidateContext';
import Question from '../Questions/Question';
import ProgressBar from "@ramonak/react-progress-bar";
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import TestCenterService from '../../services/TestCenterService';


export default function Run (props) {
    const history = useHistory();
    const [mounted, setMounted] = useState(false);
    const {candidate, setCandidate } = useCandidate();
    const [progress, setProgress] = useState(0);
    const { t } = useTranslation();
    const [current, setCurrent] = useState(0);


    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        // Render a countdown
        return <div className='flex flex-col'>
            <span className='text-right'>Time left</span>
            <span className=' font-bold text-2xl text-right'>{hours> 0 ? hours + ' : ': ''}{minutes} : {seconds <10 ? '0' + seconds : seconds}</span>
        </div>;

    };

    useEffect( () => {

        setMounted(false);

        // no more questions so jump to end
        if (candidate.currentQuestionNo === candidate.questions.length) {
            // was last question
            history.push('/end');
        }

        // display question number and its data        
        const doneQuestions = candidate.questions.slice(0, candidate.currentQuestionNo);
        //console.log('done = ' + doneQuestions.length)        
        const answeredQuestions = doneQuestions.filter (x => x.QuestionType !== 31); // answered questions that are no instructions
        //console.log('answered = ' + answeredQuestions.length);

        // calculate progress
        //const progressPercentage = (candidate.currentQuestionNo + 1) / candidate.questionsCount * 100;        
        const progressPercentage = (answeredQuestions.length + 1) / candidate.questionsCount * 100;        
        //console.log(progressPercentage)
        setProgress(Math.round(progressPercentage));

        // calculate current question no
        const arr = candidate.questions.slice(0, candidate.currentQuestionNo);
        const x = arr.filter(q => q.QuestionType != 31).length;
        setCurrent(x);
        setMounted(true);
        
    }, [candidate.currentQuestionNo]);

    const doEndTest = () => {
        // time expired so save the current question and redirect to end
        var response = candidate.questions[candidate.currentQuestionNo];
        TestCenterService.saveResponse(candidate.accessToken.token, response);            
        history.push('/end');
    }
  
    if (!mounted) 
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else
    return ( 
        <>
        <Header />

        <div className='container mx-auto'>
            <div className='flex'>
                <div className='w-1/2 sm:w-1/4 flex flex-col'>
                    { candidate.questions[candidate.currentQuestionNo] && candidate.questions[candidate.currentQuestionNo].QuestionType !== 31 ?  // do not display question number for Instructions
                        <>
                        <span className='mb-2'>{t('run.Question')} {current+1} / {candidate.questionsCount}</span>
                        <ProgressBar completed={progress} isLabelVisible={false} bgColor='#19923f' borderRadius='5px' labelColor="#ffffff" labelSize="16px"/>
                        </>
                    : '' }
                </div>
                <div className='hidden sm:block sm:w-2/4'></div>
                <div className='w-1/2 sm:w-1/4'>
                    {candidate.endByTestTime ? 
                    <Countdown  date={candidate.endByTestTime} renderer={renderer} onComplete={() => doEndTest()} />
                    : ''
                    }
                </div>
            </div>

            <div className='mt-4'>
                <Question question={candidate.questions[candidate.currentQuestionNo]} />
            </div>

        </div>
        </>
    );
}