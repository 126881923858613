import React, {useState, useEffect} from 'react';
import {useCandidate} from '../../CandidateContext';
import Comments from './Comments';
import Loader from "react-loader-spinner";

export default function QuestionTrueFalse(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [mounted, setMounted] = useState(false);

    const steps = [];
    for (let i = 1; i <= 2; i++) {
        if (props.question['A_' + i]) {
            steps.push(<div key={i}><input key={i} type='radio' id={'id'+i} className='mr-2' value={'A_' + i} checked={props.question['R_' + i] == true} onChange={e => {saveQuestionAnswers(e.target.value);}} /><label htmlFor={'id'+i}>{props.question['A_' + i]}</label></div>);
        }
    }

    useEffect( () => {        
        //console.log('loading single question')
        setResponse(props.question);
        setMounted(true);
    }, [props.question]);
        
    const saveQuestionAnswers = async(val)  => {      
        //console.log(val);  
        // reset old values
        response['R_1'] = null;
        response['R_2'] = null;
        
        // set new value        
        var r = val;
        r = r.replace('A', 'R');
        response[r] = true;

        setResponse(response);

        setCandidateData();

    };

    const saveQuestionComments = async(val) => {        
        response['R_Text'] = val;
        setResponse(response);

        setCandidateData();

    };

    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else
        return (

        <div>
            {steps}
            {props.question.Comments == true ? <Comments saveQuestionComments={saveQuestionComments} value={response['R_Text'] ? response['R_Text'] : ''}></Comments> : <></>}

        </div>
    )
}