import React, {useState, useEffect, useCallback} from 'react';
import {useCandidate} from '../../CandidateContext';
import Comments from './Comments';
import Loader from "react-loader-spinner";

export default function QuestionUpload(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [mounted, setMounted] = useState(false);

    useEffect( () => {
        setResponse(props.question)
        setMounted(true);        
    }, [props.question]);


    const handleFileChange = (e) => {   
        //console.log('handle file change')
        response['R_Text'] = e.target.files[0].name;     
        //setNewFile(e.target.files[0]);
        response.file = e.target.files[0];
        //setResponse(response);
        //console.log(e.target.files[0])

        setCandidateData();

      };

    const saveQuestionComments = async(val) => {        
        response['R_1_Text'] = val;
        setResponse(response);

        setCandidateData();
    };    

    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else

    return (

        <div>
            <input type="file" onChange={e=> handleFileChange(e)} className='' key={response['ResponseId']}/>
            {response['R_Text'] ?  <span className='ml-4'>File: {response['R_Text']}</span> : ''}    

            {props.question.Comments == true ? <Comments saveQuestionComments={saveQuestionComments} value={response['R_1_Text'] ? response['R_1_Text'] : ''}></Comments> : <></>}

        </div>
    )
}