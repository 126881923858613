import React, {useState, useEffect} from 'react';
import {useCandidate} from '../../CandidateContext';
//import Loader from "react-loader-spinner";
import TestCenterService from '../../services/TestCenterService';
import QuestionFreeText from './QuestionFreeText';
import QuestionSingleAnswer from './QuestionSingleAnswer';
import QuestionMultipleAnswer from './QuestionMultipleAnswer';
import QuestionTrueFalse from './QuestionTrueFalse';
import QuestionExactMatch from './QuestionExactMatch';
import QuestionRanking from './QuestionRanking';
import QuestionPictureChoice from './QuestionPictureChoice';
import QuestionUpload from './QuestionUpload';
import QuestionTypingSpeed from './QuestionTypingSpeed';
import QuestionVideoRecording from './QuestionVideoRecording';
import QuestionProgramming from './QuestionProgramming';
import parse from "html-react-parser";
import { useTranslation } from 'react-i18next';

export default function Question(props) {
    const [wait, setWait] = useState(false);
    const { candidate, setCandidate } = useCandidate();
    const { t } = useTranslation();
    const [time, setTime] = useState(1);

    //console.log(props);

    useEffect( () => {    
        //console.log('main question');
        //console.log(props.question)     
    }, []);

    useEffect( () => {    
        // new question so reset timer
        setTime(1);
    }, [props.question ? props.question.ResponseId : null]);

    // this use effect measures how much the candidate stays on this quesiton
    useEffect( () => {    
        const timer = setTimeout(() => {
            let seconds = time;
            seconds++;
    
            setTime(seconds);
            //console.log(seconds)
          }, 1000);
          
          // clean up function to clear the timeout
          return () => clearTimeout(timer);
      
    }, [time]);

    const doNextQuestion = async(event) => {
        setWait(true);
        // save the response , it is in the current question....
        var response = candidate.questions[candidate.currentQuestionNo];
        
        response.ResponseDuration = time;
        //await ? no need to be sync
        TestCenterService.saveResponse(candidate.accessToken.token, response);            

        // save new response
        //console.log('response saved')

        // move to the next question

        // update the state of the currentQuestion value => this will trigger the effect of the Run.js
        var tmpCurrentQuestionNo = candidate.currentQuestionNo + 1;
        var tmpCandidate  = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response; // save again response to include to duration
        tmpCandidate.currentQuestionNo = tmpCurrentQuestionNo;
        setCandidate(tmpCandidate);


        setWait(false); 
        //console.log(time)

    }
  
    const doPrevQuestion = async(event) => {
        setWait(true);
        // move to the next question
        if (candidate.currentQuestionNo === 0 ) {
            // first question so do nothing

        }
        else {
            // update the state of the currentQuestion value => this will trigger the effect of the Run.js
            const tmpCurrentQuestionNo = candidate.currentQuestionNo - 1;
            var tmpCandidate  = {...candidate};
            tmpCandidate.currentQuestionNo = tmpCurrentQuestionNo;
            setCandidate(tmpCandidate);

        }

        setWait(false); 

    }

    if (props.question)
    return (
        <>
        <div className='bg-blue-50 rounded-md p-4 lg:text-lg'>
            {props.question.QuestionType === 10 ? <div className='mb-8'>This is a typing speed and accuracy test.  Type the following text as fast as possible.  When you are done click 'Save' button. <hr/></div> : ''}
            
            <div className='w-full' id='questionTitle'>{parse(props.question.Q_Title)}</div>
            <br />
            <div className='w-full clear-both'>
            {props.question.QuestionType === 1 ? <QuestionFreeText question={props.question} /> : ''}
            {props.question.QuestionType === 2 ? <QuestionSingleAnswer question={props.question} /> : ''}
            {props.question.QuestionType === 3 ? <QuestionMultipleAnswer question={props.question} /> : ''}            
            {props.question.QuestionType === 4 ? <QuestionTrueFalse question={props.question} /> : ''}
            {props.question.QuestionType === 5 ? <QuestionRanking question={props.question} /> : ''}
            {props.question.QuestionType === 6 ? <QuestionExactMatch question={props.question} /> : ''}
            {props.question.QuestionType === 7 ? <QuestionPictureChoice question={props.question} /> : ''}
            {props.question.QuestionType === 8 ? <QuestionProgramming question={props.question} /> : ''}
            {props.question.QuestionType === 9 ? <QuestionUpload question={props.question} /> : ''}
            {props.question.QuestionType === 10 ? <QuestionTypingSpeed question={props.question} /> : ''}
            {props.question.QuestionType === 11 ? <QuestionVideoRecording question={props.question} /> : ''}
            </div>

        </div>

        <div className='flex justify-center my-4'>
            <button onClick={() => {doNextQuestion()}} className="bg-green-600 hover:bg-green-700 text-white font-bold py-1 px-2 mr-4 rounded focus:outline-none">{props.question.QuestionType !== 31 ? t('run.BtnSave') : 'Next'}
                {wait ? 
                <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>                
                : ''}
            </button>

            {candidate.currentQuestionNo > 0 ? 
            <button onClick={() => {doPrevQuestion()}} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded focus:outline-none">Go back
                {wait ? 
                <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>                
                : ''}
            </button>
            : '' }
        </div>
        </>
    )

    else 
    return '';
}