import { useState, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import QuestionVideoRecordingStreamPreview from './QuestionVideoRecordingStreamPreview';
import {useCandidate} from '../../CandidateContext';

export default function QuestionVideoRecording (props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    //const [mediaBlob, setMediaBlob] = useState();

    const {
        status,
        startRecording,
        stopRecording,
        previewStream,
        mediaBlobUrl,
    } = useReactMediaRecorder({ 
        video: true , 
        blobPropertyBag: { type: "video/webm" },
        /*onStop : (blobUrl, blob) => {
            setMediaBlob(blob);
        } */
        });

    useEffect( async () => {
        if (mediaBlobUrl) { 
            //console.log(mediaBlobUrl)
            // read the blob from media blob Url
            const mediaBlob = await fetch(mediaBlobUrl).then(response => response.blob());
        
            // create a file with the media blob and attach it to response for upload
            const file = new File(
                [mediaBlob],
                "video.webm",
                { type: 'video/webm' }
            );

            response['R_Text'] = 'video.webm';     
            response.file = file;
            setCandidateData();

        }


    }, [mediaBlobUrl]);  
  
    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

return (
    <div>
        {/*<p>{status}</p>*/}
        <div className='flex my-4'>
            {status !== 'recording' ? <button onClick={startRecording} className="bg-green-600 hover:bg-green-700 text-white font-bold py-1 px-2 mr-4 rounded focus:outline-none">Start Recording</button> : ''}            
            {status === 'recording' ? <button onClick={stopRecording} className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 mr-4 rounded focus:outline-none">Stop Recording</button> : ''}  
        </div>

        {status === 'recording' ? <QuestionVideoRecordingStreamPreview stream={previewStream} /> : ''}

        {status === 'stopped' ? <video src={mediaBlobUrl} controls disablePictureInPicture />  : ''}

        {(status !== 'stoppped' && status !== 'recording' && response['R_Text'] && response['R_Text'] !== 'video.webm') ? <video src={process.env.REACT_APP_CDN_URL + '/test_answers/' + response['R_Text']} controls disablePictureInPicture/> : <></>}

      
    </div>
  );
};
