import React, {useCallback} from 'react';

export default function Comments(props) {
      
    const handleCommentsChange = async(val)=> {
  
        props.saveQuestionComments(val)                          
    };


  
    return (

        <div className='mt-4'>
            Comments: <br />
            <textarea onChange={e => {handleCommentsChange(e.target.value)}} className='border-black border-2 rounded w-full' value={props.value}></textarea>
        </div>


    )
}