import React, {useState, useEffect} from 'react';
import {useCandidate} from '../../CandidateContext';
import Comments from './Comments';
import Loader from "react-loader-spinner";

export default function QuestionPictureChoice(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [answers, setAnswers] = useState([]);
    const [mounted, setMounted] = useState(false);

    /*
    const steps = [];
    for (let i = 1; i <= 12; i++) {
        if (props.question['A_' + i]) {
            steps.push(<img key={i} src={process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.question['A_' + i]} onClick={e => saveQuestionAnswers('A_' + i)}  className='mr-4 hover:cursor-pointer hover:border-2 hover:border-black'/>);
        }
    }*/

    useEffect( () => {        
        //console.log('loading picture choice question')
        setResponse(props.question);
        setMounted(true);
    }, [props.question]);


    useEffect( () => {
        //console.log('generate responses')
        //console.log(response)
        const steps = [];        
        for (let i = 1; i <= 12; i++) {
            if (response['A_' + i]) {
                steps.push(<img key={i} src={(response['A_' + i].indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? response['A_' + i] : process.env.REACT_APP_CDN_URL + '/picturechoice/' + response['A_' + i])} onClick={e => saveQuestionAnswers('A_' + i)}  className={'mr-4 mb-4 hover:cursor-pointer rounded hover:border-8 hover:border-green-600 ' + (response['R_' + i] == true ? 'border-8 border-green-600' : 'border-none')}/>);
            }
        }
        setAnswers(steps);

    }, [response]);

    const saveQuestionAnswers = async(val)  => {      

        // no need to reset all as it's a multiple checkbox but i need to check the "checked" event
        //console.log(val)
        // set new value      
        const r = val.replace('A', 'R');
        if (response[r] == true)
            response[r] = null;
        else
            response[r] = true;
        setResponse({...response});

        setCandidateData();          
    };

    const saveQuestionComments = async(val) => {        
        response['R_Text'] = val;
        setResponse(response);

        setCandidateData();
    };

    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else
    return (

        <div>
            <div className='flex flex-wrap'>
            {answers}
            </div>

            {props.question.Comments == true ? <Comments saveQuestionComments={saveQuestionComments} value={response['R_Text'] ? response['R_Text'] : ''}></Comments> : <></>}

        </div>
    )
}