import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import TestCenterService from '../../services/TestCenterService';
import {useCandidate} from '../../CandidateContext';
import Feedback from './Feedback';
import { useTranslation } from 'react-i18next'
import Header from './Header';


export default function End (props) {
    const {candidate, setCandidate } = useCandidate();
    const [feedbackText, setFeedbackText] = useState(null);
    const [feedbackLevel, setFeedbackLevel] = useState(null);
    const [finished, setFinished] = useState(false);
    const { t } = useTranslation();

    const doSubmitFeedback = async(event) => {
        setFinished(true);

        doEndTestSession();
    }

    const doEndTestSession = () => {
        // no need to async this function
        console.log('end test')
        TestCenterService.endTestSession(candidate.accessToken.token, candidate.firstName, candidate.lastName, candidate.recruiterId, feedbackLevel, feedbackText, candidate.importSource); // no need to await the execution, let it finish on the server side
        // save all responses to mongo
        TestCenterService.saveResponses(candidate.accessToken.token, candidate.firstName, candidate.lastName, feedbackLevel, feedbackText, candidate.questions); // no need to await the execution, let it finish on the server side
        //
        setCandidate(null);

    }


    useEffect(  () => {
        //if (!candidate) history.push('/');

        if (candidate && (candidate.feedbackRequired == false || Date.parse(candidate.endByTestTime) < Date.now())) {
            // feedback is not required so end the session right here
            doEndTestSession();
        }

    }, []);

  

    if (candidate && candidate.feedbackRequired == true && finished == false)
        return (
            <Feedback setFeedbackLevel={setFeedbackLevel} setFeedbackText={setFeedbackText} doSubmit={doSubmitFeedback}></Feedback>
        );
    else 
        return ( 
            <>
            <Header />
            <div className='container mx-auto text-center  rounded-md p-4 lg:text-lg'>
            <p className='font-bold'>{t('end.IntroTitle')}</p>
            <br />
            <p>{t('end.IntroMessage')}</p>
            <br />
            <p>Have another PIN code ?  <Link to='/' className='underline text-blue-600 hover:text-blue-800'>Go back to the test center</Link></p>
            </div>
            </>
        );
}