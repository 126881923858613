import React from 'react';

export default function QuestionVideoRecordingStreamPreview({ stream }) {
    let videoPreviewRef = React.useRef();
  
    React.useEffect(() => {
      if (videoPreviewRef.current && stream) {
        videoPreviewRef.current.srcObject = stream;
      }
    }, [stream]);
  
    if (!stream) {
      return null;
    }
  
    return <video ref={videoPreviewRef} width={520} height={480} autoPlay />;
  }
  
  