import React, {useState, useEffect} from 'react';
import {useCandidate} from '../../CandidateContext';
import Comments from './Comments';
import Loader from "react-loader-spinner";

export default function QuestionExactMatch(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [mounted, setMounted] = useState(false);

    useEffect( () => {      
        setResponse(props.question);
        setMounted(true)          
    }, [props.question]);
    
    const saveQuestionAnswers = async(val) => {        
        response['R_Text'] = val;
        setResponse(response);

        setCandidateData();

    };

    const saveQuestionComments = async(val) => { 
        response['R_1_Text'] = val;
        setResponse(response);

        setCandidateData();
    }; 

    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else     
    return (

        <div>

            {props.question.A_2 == 'number' ?
                <input type='number' step='any' onChange={e=> {saveQuestionAnswers(e.target.value)}} value={response['R_Text'] ? response['R_Text'] : ''} className='w-full border-2 border-black rounded' />
                :
                <textarea onChange={e=> {saveQuestionAnswers(e.target.value)}} value={response['R_Text'] ? response['R_Text'] : ''} className='w-full h-40 border-2 border-black rounded'></textarea>
            }

            {/*<textarea onChange={e=> {saveQuestionAnswers(e.target.value)}} value={response['R_Text'] ? response['R_Text'] : ''} className='w-full h-40 border-2 border-black rounded'></textarea>*/}

            {props.question.Comments == true ? <Comments saveQuestionComments={saveQuestionComments} value={response['R_1_Text'] ? response['R_1_Text'] : ''}></Comments> : <></>}

        </div>
    )
}