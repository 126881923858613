import axios from "axios";


class TestCenterService {


  auth = async (pinCode) => {
    //const ret = await axios.get(process.env.REACT_APP_SKILLMETER_API + "/testsession/auth/" + pinCode);
    //return ret.data;

    let fd = new FormData();
    fd.append('pinCode', pinCode);

    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/testsession/", fd)
      .then ( (res) => {
        if (res.status == '204')
          return 'error204';
        else
          return res.data;
      }).catch( (err)=> {
        return 'error204';
      });

  }

  getCompanyDetails = async (subdomain) => {
    const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/companies/subdomain/" + subdomain);
    return ret.data;
  }

  getQuestions = async (pinCode) => {
    return axios.get(process.env.REACT_APP_SKILLMETER_API + "/responses/" + pinCode)
      .then ( (res) => {
          return res.data;
      });
  }
 
  getTestDetails = async (token) => {
    const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/testsession/testdetails/", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
    return ret.data;
  }

  // run code question type
  runCode = async (token, language, code, input) => {

    let fd = new FormData();
    fd.append('language', language);
    fd.append('code', code);
    fd.append('input', input);

    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/testsession/runcode/" , fd,  { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} })
      .then ( (res) => {
        console.log(res);
          return res.data;
      });
  }  

  saveResponse = async (token, response) => {

    let fd = new FormData();
    fd.append('response', JSON.stringify(response));
    if (response.file) {
      fd.append('file', response.file)
    }

    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/responses/" , fd,  { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} })
      .then ( (res) => {
          return res.data;
      });
  }  

  // save all responses to mongo
  saveResponses = async (token, firstName, lastName, feedbackLevel, feedbackText, responses) => {

    let fd = new FormData();
    fd.append('firstName', firstName);
    fd.append('lastName', lastName);
    fd.append('feedbackLevel', feedbackLevel);
    fd.append('feedbackText', feedbackText);
    fd.append('responses', JSON.stringify(responses));

    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/responses/all" , fd,  { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} })
      .then ( (res) => {
          return res.data;
      });
  }  

  startTestSession = async (testStartDate, testDuration, allowResume, token) => {
    let fd = new FormData();
    fd.append('testStartDate', testStartDate == null ? '': testStartDate);
    fd.append('testDuration', testDuration);
    fd.append('allowResume', allowResume);

    const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/testsession/start" , fd,  { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
    return ret.data;
  }  

  endTestSession = async (token, firstName, lastName, recruiterId, feedbackLevel, feedbackText, importSource) => {

    let fd = new FormData();
    fd.append('feedbackLevel', feedbackLevel == null ? '': feedbackLevel);
    fd.append('feedbackText', feedbackText ==null ? '': feedbackText);
    fd.append('candidateFirstName', firstName);
    fd.append('candidateLastName', lastName)
    fd.append('recruiterId', recruiterId);
    fd.append('importSource', importSource);

    await axios.post(process.env.REACT_APP_SKILLMETER_API + "/testsession/end" , fd,  { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
  }  

  registerForTest = async (firstName, lastName, email, userId, testId, testName, site, company) => {
    let fd = new FormData();
    fd.append('firstName', firstName);
    fd.append('lastName', lastName);
    fd.append('email', email);
    fd.append('userId', userId);
    fd.append('testId', testId);
    fd.append('testName', testName);
    fd.append('site', site);
    fd.append('company', company);

    const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/testsession/registerfortest" , fd);
    return ret.data;
  }  

  getTestByUrl = async (id) => {
    return axios.get(process.env.REACT_APP_SKILLMETER_API + "/tests/byurl/" + id)
      .then ( (res) => {
          return res.data;
      });
  }

}

export default new TestCenterService();
