import React , {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

export default function TrackingCode(props) {

    const location = useLocation();

    // location change so call GA
    useEffect( () => {
        // google analytics
        /*
        ReactGA.initialize('UA-13135252-1'); 
        ReactGA.pageview(window.location.pathname + window.location.search);
        */
        ReactGA.initialize('G-7KCSVR7JP8');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});

      }, [location]);

    return ('');
}