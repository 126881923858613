import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import TestCenterService from '../../services/TestCenterService';
import {useCandidate} from '../../CandidateContext';
import { useTranslation } from 'react-i18next'
import StartIntroText  from './StartIntroText';
import Header from './Header';

export default function Start (props) {
    const history = useHistory();
    const [mounted, setMounded]= useState(false);
    const [wait, setWait] = useState(false);
    const {candidate, setCandidate } = useCandidate();
    const [testDuration, setTestDuration] = useState(0);
    const { t } = useTranslation();

    useEffect( async () => {


        //console.log('getting test details')

        if (candidate.testDuration === 0) {
            const ret = await TestCenterService.getTestDetails(candidate.accessToken.token);
            //console.log(ret)
            
            // calculate test duration
            let totalTime = 0;
            for (let i=0; i<ret.length; i++) {
                if (ret[i].Duration) {
                    totalTime += ret[i].Duration;
                }
                else {
                    totalTime= 0;
                    break;
                }
            }

            setTestDuration(totalTime);

        }
        else {
            // candidate has already a start duration (in resume mode)
            setTestDuration(candidate.testDuration);
            //
            /*
            console.log(candidate.testDuration)
            var n = new Date(0,0);
            n.setSeconds(+candidate.testDuration*60);
            console.log(n) 
            var result = n.toTimeString().slice(0, 8);
            console.log(result)*/

        }

        setMounded(true);

    }, []);

    const doSubmit = async(event) => {
        setWait(true);
        
        // mark the beginning of the test & get the list of questions
        const questionList = await TestCenterService.startTestSession(candidate.testStartDate, testDuration, candidate.allowResume, candidate.accessToken.token);    
        // save questionList 
        candidate.questions = questionList;

        // if test is timed, calculate the endTestTime 
        if (testDuration > 0 ) {
            /*
            const durationSpent = questionList.reduce(function (sum, item) {
                return sum + item.ResponseDuration;
            }, 0);

            candidate.endByTestTime = new Date(Date.now() + (testDuration-durationSpent/60)*60000);
            */
            candidate.endByTestTime = new Date(Date.now() + testDuration*60000);
            candidate.testDuration = testDuration;

        }
        // save candidate in context
        setCandidate(candidate);

        // move to run
        history.push('/run');
       
        // done
        setWait(false);

    }

   

    if (!mounted) 
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else 
    return ( 
    <>
    <Header />

    <div className='container mx-auto bg-blue-50 rounded-md p-4 lg:text-lg'>
        <StartIntroText subdomain={candidate.subdomain} testDuration={testDuration} questionsCount={candidate.questionsCount} />
    </div>

    <div className='flex justify-center my-4'>
        <button onClick={() => {doSubmit()}} className="flex justify-center whitespace-pre bg-green-600 hover:bg-green-700 text-white font-bold text-lg py-1 px-2 rounded focus:outline-none">{t('start.StartBtn')}
            {wait ? 
            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>            
            : ''}
        </button>
    </div>
    </>
    );
}