import React, {useState, useEffect} from 'react';
import {useCandidate} from '../../CandidateContext';
import Comments from './Comments';
import Loader from "react-loader-spinner";

export default function QuestionRanking(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [mounted, setMounted] = useState(false);


    useEffect( () => {        
        //console.log('loading ranking question')
        setResponse(props.question);
        setMounted(true);
    }, [props.question]);


    const saveQuestionAnswers =  (target, e) => {      
        //console.log('target = ' +  target + ' , val = ' + e.target.value);  

        // set value        
        //response[target] = e.target.value;
        //console.log(target)
        //console.log(e.target.value)
      
        // aici vad daca elementul asta mai e undeva in raspunsuri, si sa-l sterg pt ca s-a ales o noua pozitie
        for (let i=1;i<=12; i++) {
            if (response['R_' + i + '_Text'] == props.question['A_' + target])
                response['R_' + i + '_Text'] = null;
        }
      
        // salvez noua pozitie a elementului 
        //response['R_' + target + '_Text'] = props.question['A_' + e.target.value];
        response['R_' + e.target.value + '_Text'] = props.question['A_' + target];

        // ar trebuie inainte sa vad daca elementul asta mai e undeva in raspunsuri, si sa-l sterg

        setResponse(response);
        //console.log('response');
        //console.log(response)
        setCandidateData();
    };

    const saveQuestionComments = async(val) => {        
        response['R_Text'] = val;
        setResponse(response);

        setCandidateData();
    };

    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    const getSelectedValue = (item) => {
        //console.log(item)
        let ret = 0;
        
        for (let i=0; i<props.question.rankingOrder.length; i++) {
            //if (props.question['R_' + item + '_Text'] === props.question['A_' + (i+1)]) {
                if (props.question['A_' + item ] === props.question['R_' + (i+1) + '_Text']) {
                ret = i+1; 
                break;
            }
        }


        return ret;
    }

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else
    return (

        <div>
            {props.question.rankingOrder.map( (item,i) => {
                return ( 
                <div key={i} className='mb-2'>
                    <select key={i} onChange={e => saveQuestionAnswers(item, e)}  className='mr-2 border-2 border-black rounded px-1'>
                        <option ></option>
                        {Array.from(Array(props.question.rankingOrder.length), (v,k) => k+1).map( (item2, j) => {
                            return <option key={j}  selected={(item2 == getSelectedValue(item)) ? true:false}>{item2}</option>
                        })}
                    </select>
                    {props.question['A_' + item]}

                </div> )
            })}
            {props.question.Comments == true ? <Comments saveQuestionComments={saveQuestionComments} value={response['R_Text'] ? response['R_Text'] : ''}></Comments> : <></>}
        </div>
    )
}