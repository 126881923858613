import React , {useEffect} from 'react';

export default function Header(props) {

    // location change so call GA
    useEffect( () => {

      }, []);

    return (
        <div className={'mb-10 mt-10 text-center '}>
        {/*<p className='text-center'><a href='/' className='underline text-blue-600 hover:text-blue-800'>Go Back</a>  Running test ...</p>*/}
        </div>
    );
}