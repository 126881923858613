import React, {useState, useEffect, useCallback} from 'react';
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import TestCenterService from '../../services/TestCenterService';
import {useCandidate} from '../../CandidateContext';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';

export default function Index (props) {

    const [pinCode, setPinCode] = useState(null);
    const history = useHistory();

    const [wait, setWait] = useState(false);
    const [error, setError] = useState(false);
    const {setCandidate } = useCandidate();
    const {language } = useParams();    
    const { t, i18n } = useTranslation()
    const [company, setCompany] = useState('Skillmeter');
    const [mounted, setMounted] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState('en');

    const getSubdomain =  () => {
        const parts = window.location.hostname.split('.');
    
        let last_index = -2;
        const last = parts[parts.length - 1];
        const is_localhost = last === 'localhost';
        if (is_localhost) {
          last_index = -1;
        }
    
        const subdomain = parts.slice(0, last_index).join('.');

        if (!subdomain) {
          return '';
        }
        else {
            return subdomain;
        }
    

      }


    useEffect( async () => {
        if (language) {
            i18n.changeLanguage(language);
            setCurrentLanguage(language);

        }
        if (props.language) {
            i18n.changeLanguage(props.language);
            setCurrentLanguage(props.language);
        }

        const subdomain = getSubdomain();
        if (subdomain !== '' && subdomain !== 'localhost' && subdomain !== 'test' && subdomain !== 'testcenter') {
            const comp = await TestCenterService.getCompanyDetails(subdomain);
            if (comp && comp !=='') {
                setCompany(comp);
            }
            //console.log(comp);
        }

        setMounted(true);

    }, []);


    const handleKeypress = e => {
      // enter key pressed ? do submit
      if (e.key === 'Enter') {
        doSubmit();
      }
    };

    const doSubmit = async() => {
        setWait(true);
        
        // check pincode
        let retPin = '';
        
        if (!pinCode || pinCode === '')
            retPin = 'error204';
        else
            retPin = await TestCenterService.auth(pinCode);

        if (retPin != 'error204') {
            // good pin so moving forward
            
            retPin.currentQuestionNo = 0;
            setCandidate(retPin);

            // jump to start
            history.push('/start');
        }
        else {
            setError(true);
        }
       
        // done
        setWait(false);
    }
  
    if (!mounted) 
    return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);
    else 
    return (
        <>
        <div className=''>

            <div className={'mb-10 mt-10 text-center '}>
                {company && company.Logo && company.Logo != 'null'?
                <img src={process.env.REACT_APP_CDN_URL + '/company_img/' + company.Logo} className='mx-auto' style={{'max-height': '100px'}}/>
                : 
                <img src='/sm-logo.png' className='mx-auto w-52'/>

            }
            </div>    
            <p className='text-center font-bold text-lg'>{t('index.Intro1').replace('Skillmeter', company == 'Skillmeter' ? 'Skillmeter' : company.CompanyName)}</p>
            <p className='text-center text-lg'>{t('index.Intro2')}</p>

            <br /><br />
            <div className='flex flex-wrap justify-center'>
                <span className='text-lg pt-1'>{t('index.Pincode')}</span>
                <input type='text' onChange={e => { setPinCode(e.target.value.trim());}} onKeyPress={handleKeypress} className='ml-2 px-2 border-black border-2 rounded text-lg focus:outline-none'/>

                <button onClick={() => {doSubmit()}} disabled={props.maintenance.maintenance} className="ml-2 flex justify-center whitespace-pre bg-green-600 hover:bg-green-700 text-white font-bold text-lg py-1 px-2 rounded focus:outline-none">{t('index.Submit')}
                    {wait ?  
                    <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>: ''}
                </button>
                {error ? <div className='w-full flex justify-center'><span className='text-red-500 text-lg'>Invalid PIN code</span><br /></div> :<></>}

            </div>
        </div>

        <Footer language={currentLanguage}/>
    </>
    );
}