import LanguageSelector from './LanguageSelector';
const json = require('../../../package.json');

export default function Footer(props) {

    return (
        <div className='absolute bottom-2 w-full'>
            <p className='text-xs text-gray-500 text-center'> Test Center {process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV: ''} v{json.version} by <a href='https://skillmeter.com' className='hover:text-gray-700 hover:font-bold'>Skillmeter</a> | <a href='mailto:support@skillmeter.com' className='hover:text-gray-700 hover:font-bold'>Support</a> | 
            <LanguageSelector {...props}/>
            </p>
            
        </div>

    );
}