import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Index from './components/Home/Index';
import NotFound from './components/NotFound/Index';
import Run from './components/Home/Run';
import Start from './components/Home/Start';
import End from './components/Home/End';
import {CandidateContext} from './CandidateContext.js';
import './i18n';
import ProtectedRoute from './ProtectedRoute';
import TrackingCode from './TrackingCode';
import PublicTest from './components/Home/PublicTest';
import {MathJaxContext} from 'better-react-mathjax'
import {useMaintenanceStatus} from './hooks/useMaintenanceStatus.js';
import MaintenanceBar from './components/MaintenanceBar.js';


export default function App(props) {

  const [candidate, setCandidate] = useState(null); 
  const isMaintenance = useMaintenanceStatus();
  //console.log(isMaintenance)

  // hook functions sent to the context below
  const setCandidateAndToken = (data) => {
    //AuthenticationService.saveCurrentUser(data);
    // save token in cookie

    //console.log('set candidate hook: ');
    //console.log(data);
    // save candidate data
    setCandidate(data);
  }
  

  return (

    <MathJaxContext>

    <div>
        {isMaintenance && isMaintenance.maintenance === true ? <MaintenanceBar reason={isMaintenance.reason}/> : <></>}

        <Suspense fallback="">
        
          <BrowserRouter>

            <TrackingCode />

            <CandidateContext.Provider value={{candidate, setCandidate: setCandidateAndToken}}>
                <Switch>
                    <Route exact path="/" render={props =>
                      <Index maintenance={isMaintenance} />
                    }/>
                    <Route path="/index/:language" render={props => 
                      <Index key={props.match.params.language || 'empty' } maintenance={isMaintenance}/> }>
                    </Route> 
                    <Route path="/en" render={props => 
                      <Index key={'en' } language='en' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/de" render={props => 
                      <Index key={'de' } language='de' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/ro" render={props => 
                      <Index key={'ro' } language='ro' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/de" render={props => 
                      <Index key={'de' } language='de' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/es" render={props => 
                      <Index key={'es' } language='es' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/fr" render={props => 
                      <Index key={'fr' } language='fr' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/nl" render={props => 
                      <Index key={',l' } language=',l' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/sv" render={props => 
                      <Index key={'sv' } language='sv' maintenance={isMaintenance}/> }>
                    </Route>
                    <Route path="/hu" render={props => 
                      <Index key={'hu' } language='hu' maintenance={isMaintenance}/> }>
                    </Route>
                    <ProtectedRoute path="/start" component={Start}/>
                    <ProtectedRoute path="/run" component={Run}/>
                    <Route path="/end" component={End}/>
                    <Route path="/t/:id" render={props => 
                      <PublicTest id={props.match.params.id || 'empty' } /> }>
                    </Route> 
                    <Route path='*' exact={true} component={NotFound} />
                </Switch>
            </CandidateContext.Provider>

          </BrowserRouter>

      </Suspense>
    </div>
  </MathJaxContext>

  )
}
