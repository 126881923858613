import React, {useState, useEffect, useRef} from 'react';
import {useCandidate} from '../../CandidateContext';
import Loader from "react-loader-spinner";

export default function QuestionTypingSpeed(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [wpm, setWpm] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [timer, setTimer]= useState(null);
    const [timerDuration, setTimerDuration]= useState(0);

    const textInputRef = useRef();

    useInterval( () => {
        if (timer) {
            const newDuration = timerDuration + 1;
            setTimerDuration(newDuration);
            // Update properties
            var words = (response['R_Text'] ? response['R_Text'] : '').length / 5; // average a word of 5 chars
            //console.log('words = ' + words)
            //console.log(timerDuration)
            // Divide words by the number of minutes that have elapsed
            setWpm(Math.round((words / (timerDuration)) * 60 * 10));

            // save the WPM
            response['R_1_Text'] = wpm;
            setResponse(response);
    
            setCandidateData();            
        }
    }, 100);

    function useInterval(callback, delay) {
        const savedCallback = useRef();
      
        // Remember the latest function.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        // Set up the interval.
        useEffect(() => {
          function tick() {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
      }

    const focusInput = () => {
        textInputRef.current.focus();
      };

    useEffect( () => {
        if (mounted) focusInput();
    }, [mounted]);

    useEffect( () => {        

        setResponse(props.question);
        setMounted(true)

    }, [props.question]);

    const calculateAccuracy = (val) => {
        let correctChars = 0;

        for (var i = 0; i < val.length; i++) {
            if (response['Q_Title'][i] === val[i])
                correctChars++;
        }

        const ret = correctChars/val.length * 100;
        return ret.toFixed(2);
    }


    const saveQuestionAnswers = async(val) => {    
        // If the timer hasn't started, start it
        if (!timer) {
            setTimer ('started')
        }

        // calculate accuracy
        const acc = calculateAccuracy(val);
        //console.log(acc);
        setAccuracy(acc);

        response['R_Text'] = val; // save value typed
        response['R_2_Text'] = acc; // save accuracy
        setResponse(response);

        setCandidateData();
        
        // Check what is the current word and highlight it        
        const words = val.split(" ");
        const currentWord = words.length - 1;

        let s = '';
        const originalWords = props.question.Q_Title.replace(/\s\s+/g, ' ').split(" ");
        let highlightOriginalWords = originalWords.slice();  // copy array by value and not by ref in JS

        for (var i = 0; i < originalWords.length; i++) {
            if (i < currentWord)
                highlightOriginalWords[i] = "<span style='color:#bdbdbd'>" + highlightOriginalWords[i] + "</span>";

            if (i == currentWord)
                highlightOriginalWords[i] = "<span style='color:blue'>" + highlightOriginalWords[i] + "</span>";
            
            highlightOriginalWords[i] = " " + highlightOriginalWords[i];
            //s += highlightOriginalWords[i];
        }

        //document.getElementById('questionTitle').innerHTML = s;
        document.getElementById('questionTitle').innerHTML = highlightOriginalWords.join(''); //concatenates the array as string
        // end highlight
        
    };


    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else    
    return (

        <div>
            <textarea className='w-full' rows={5} ref={textInputRef} onChange={e=> {saveQuestionAnswers(e.target.value)}} value={response['R_Text'] ? response['R_Text'] : ''} placeholder="Type here to start .."></textarea>
            
            <br />

            <div className={response['A_1_Correct'] == true ? 'hidden' : ''}>
                Speed: {wpm} wpm
                <br />
                Accuracy: {accuracy} %
            </div>


         </div>
    )
}