import React from 'react';
import { useTranslation } from 'react-i18next'
import {useCandidate} from '../../CandidateContext';


export default function StartIntroText (props) {
    const { t } = useTranslation()
    const {candidate } = useCandidate();


    if (props.subdomain == '5ive') {
        return ( 
        <div>
            <p className='font-bold text-center'>{t('start.IntroTitle')} </p>
            <div style={{direction:'rtl'}}>
            <span>לפניך מבדק למועמדים למשרה בחברת 5IVE.</span>
            <span>משך הזמן המוקצה למבדק הינו {props.testDuration} דקות. חשוב להכין ניירות טיוטה ועט.</span>
            <br /><br />
            <span>
                המבדק כולל {props.questionsCount} שאלות. השאלות אינן שוות במשקלן היחסי, וגם במשך הזמן הנחוץ לפתרונן. לעיתים קרובות ככל שנוסח השאלה ארוך יותר – הזמן הנדרש לפתרונה ארוך יותר, ובדרך כלל גם משקלה בהערכת המבדק גבוה יותר.
                לעיתים יוצג תרחיש אשר רלוונטי לרצף של מספר שאלות המתייחסות לאותו מקרה. כדי שלא תאלצו לזכור את התרחיש - הוא יובא שוב ושוב לפני כל שאלה רלוונטית (מלל התרחיש נותר זהה לגמרי).
            </span>
            <br /><br />
            <span>במידה והזמן לא יספיק – ניתן לסיים את המבדק עם שאלות שאינן פתורות. יש לקחת בחשבון כי פתרון שגוי גורר הורדת ניקוד.</span>
            <br /><br />
            בהצלחה!
        </div>
        </div>
        );
    }
    else {
        return (
        <div className='text-lg'>
            <p className='font-bold text-center'>{t('start.IntroTitle')} </p>
            <br />
            <p>{t('start.IntroLine1').replace('%QUESTIONS%', props.questionsCount)} </p>
            {props.testDuration > 0 ? <p>{t('start.IntroLine2').replace('%DURATION%', props.testDuration)} </p> : ''}
            <p>{t('start.IntroLine3')} </p>
            <p>{t('start.IntroLine4')} </p>
            {candidate.allowResume != true ? <p>{t('start.IntroLine5')} </p> : <></>}
            

        </div>
        );
    }
}