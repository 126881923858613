import React, {useState, useEffect} from 'react';
import {useCandidate} from '../../CandidateContext';
import Loader from "react-loader-spinner";
import AceEditor from "react-ace";
import 'ace-builds/webpack-resolver';
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"


import TestCenterService from '../../services/TestCenterService';


export default function QuestionProgramming(props) {
    const { candidate, setCandidate } = useCandidate();
    const [response, setResponse] = useState(props.question);
    const [mounted, setMounted] = useState(false);
    const [output, setOutput] = useState();
    const [showStdin, setShowStdin] = useState(false);
    const [wait, setWait] = useState(false);

    useEffect( () => {        
        setResponse(props.question);
        setMounted(true)
    }, [props.question]);

    
    const saveQuestionAnswers = async(val) => {        
        response['R_Text'] = val;
        setResponse(response);

        setCandidateData();
    };

    const saveStdin = async(val) => {        
        response['A_2'] = val;
        setResponse(response);

        setCandidateData();
    };

    const setCandidateData = () => {
        // set back to Candidate data
        var tmpCandidate = {...candidate};
        tmpCandidate.questions[candidate.currentQuestionNo] = response;
        setCandidate(tmpCandidate);
    }

    const doRunSolution = async(event) => {
        setWait(true);
        setOutput('... running program ...')
        const res = await TestCenterService.runCode(candidate.accessToken.token, response['CodeLanguage'] , response['R_Text'] ? response['R_Text'] : response['A_1'] , response['A_2'] );            
        //console.log(res)
        setOutput(res);

        // save the output in the response object
        response['R_1_Text'] = res;
        setResponse(response);
        setCandidateData();

        setWait(false); 

    }    

    if (!mounted)
        return (<div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>);

    else    
    return (

        <div>
            <AceEditor
                width={ '100%' }
                mode="javascript"
                theme="github"
                onChange={val=> {saveQuestionAnswers(val)}}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={response['R_Text'] ? response['R_Text'] : response['A_1']}
                setOptions={{
                    useWorker: false,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
                /*
                useWorker= {false}
                enableBasicAutocompletion={false}
                enableLiveAutocompletion={false}
                showLineNumbers= {true}
                tabSize= {2}
                */                
            />

            <div className={'mt-4 ' + (showStdin ? '' : 'hidden')}> 
                Enter input (stdin): <br />
                <textarea onChange={e=> {saveStdin(e.target.value)}} className='border-black border-2 rounded w-full' value={response['A_2'] ? response['A_2'] : ''}></textarea>
            </div>

            <div className='mt-4'>
                <button onClick={() => setShowStdin(!showStdin)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 mr-4 rounded focus:outline-none">stdin</button>

                <button onClick={() => {doRunSolution()}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none">Run code
                {wait ? 
                <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>                
                : ''}

                </button>                
            </div>

            <div className='mt-4'>
                Output: <br />
                <textarea className='border-black border-2 rounded w-full' value={output}></textarea>
            </div>

        </div>
    )
}